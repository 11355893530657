
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DropdownItem } from 'element-ui';

export interface CustomDropdownItem {
    action?: () => void;
    children?: CustomDropdownItem[];
    disable?: boolean;
    isVisible?: boolean;
    link?: string;
    text: string;
    title?: string;
}

@Component({
    name: 'CustomDropdownOption',
    components: {
        'el-dropdown-item': DropdownItem,
    },
})
export default class CustomDropdownOption extends Vue {
    @Prop({ type: Object, required: true })
    private item!: CustomDropdownItem;

    public get isActionLink() {
        return this.item.action !== undefined;
    }

    public get haveNestedOptions() {
        return this.item.children !== undefined;
    }

    public get isRouterLink() {
        return this.item.link !== undefined;
    }

    public get isExternalLink() {
        const { link } = this.item;

        if (link === undefined) {
            return false;
        }

        const isExternalPattern = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/;
        return isExternalPattern.test(link);
    }
}
