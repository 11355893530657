import { render, staticRenderFns } from "./filters-section.vue?vue&type=template&id=23be1c2e&scoped=true&"
import script from "./filters-section.vue?vue&type=script&lang=ts&"
export * from "./filters-section.vue?vue&type=script&lang=ts&"
import style0 from "./filters-section.vue?vue&type=style&index=0&id=23be1c2e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23be1c2e",
  null
  
)

export default component.exports