
import moment from 'moment';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import CustomSelectBordered, { Item } from '@/modules/common/components/ui-kit/custom-select-bordered.vue';

@Component({
    components: { CustomSelectBordered },
})
export default class MonthFilter extends Vue {
    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disabled!: boolean;

    @Prop({
        type: Number,
        required: true,
    })
    value!: number;

    get month() {
        return this.value;
    }

    set month(v: number) {
        this.$emit('input', v);
    }

    get dayItems(): Item[] {
        return moment.months().map((item, index) => ({
            name: item,
            value: index + 1,
        }));
    }
}
