var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'custom-select': true,
        'custom-select--disabled': _vm.disabled,
        'skeleton skeleton--gray': _vm.$attrs.skeleton,
    },attrs:{"title":_vm.title}},[(_vm.label)?_c('label',{staticClass:"custom-select__label",attrs:{"title":_vm.label}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('el-select',{staticClass:"custom-select__select-element",attrs:{"popper-class":"custom-select","collapse-tags":"","size":"mini","placeholder":_vm.placeholder || 'Select',"disabled":_vm.disabled,"multiple":_vm.multiple,"filterable":_vm.filterable},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},[_c('div',{staticClass:"custom-select__spacer"}),(_vm.$slots.childBeforeExtra || _vm.$slots.childBefore)?_c('div',{class:{
                'custom-select__header': true,
                'custom-select__header--sticky': _vm.stickyHeader
            }},[_vm._t("childBeforeExtra"),_vm._t("childBefore")],2):_vm._e(),_c('div',{staticClass:"custom-select__list-wrapper",style:({ marginTop: _vm.extraMarginDown(_vm.stickyHeader) + 'px' })},_vm._l((_vm.items),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value,"disabled":item.disabled}})}),1),_vm._t("childAfter")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }